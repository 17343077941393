import React, { MouseEventHandler } from "react";
import styled from "styled-components";
import PlayIcon from "./PlayIcon";

const Paused = styled.span`
  font-weight: 600;
  font-size: 24px;
`;
const Button = styled.button`
  background: #1db954;
  border-radius: 50%;
  color: #fff;
  height: 50px;
  width: 50px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  &:disabled {
    background: #ccc;
  }
  &:hover {
    opacity: 0.9;
  }
`;
type PlayButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isPlaying: boolean;
};
const PlayButton = ({ onClick, isPlaying, ...props }: PlayButtonProps) => {
  return (
    <Button onClick={onClick} {...props}>
      {isPlaying ? <Paused>| |</Paused> : <PlayIcon />}
    </Button>
  );
};

export default PlayButton;
