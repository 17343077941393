import React from "react";
import styled from "styled-components";
import { usePlaybackContext } from "../../contexts/playback";

const Wrapper = styled.div`
  color: #fff;
  position: relative;
`;
const PopUpContent = styled.div<{ show: boolean }>`
  position: absolute;
  top: 4px;
  left: 0;
  z-index: 10;
  transition: 0.1s opacity ease-in;
  width: 200px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  display: flex;
  flex-direction: column;
  ${({ show }) =>
    show &&
    ` 
    padding: 8px;
  height: auto;
  overflow: auto;
  opacity: 1;
  background: rgb(22, 36, 46);
  border-radius: 8px;
  button {
    display: block;
  }
`}
`;
const IconButton = styled.button`
  background: transparent;
  font-size: 30px;
  padding: 0;
  color: #fff;
  margin-top: -4px;
  margin-left: 8px;
`;
const PopUp = styled.div`
  color: #fff;
  position: relative;
`;
const ActiveDevice = styled.button`
  font-size: 14px;
  text-align: left;
  color: #1db954;
  background: transparent;
  cursor: pointer;
  font-weight: 600;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    opacity: 0.5;
  }
`;
const Current = styled.div`
  display: flex;
  > div:first-of-type {
    margin-right: 8px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TitleText = styled.div`
  padding: 8px 16px 4px 16px;
  font-size: 14px;
  font-weight: 600;
`;

const DeviceOptionButton = styled.button`
  background: transparent;
  color: #fff;
  font-size: 16px;
  text-align: left;
  padding: 8px 16px;
  &:hover {
    background: gray;
  }
`;
const DeviceDisplay = styled.div`
  padding-bottom: 2px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
`;
const DevicesList = () => {
  const { devices, playbackState, transferPlayback } = usePlaybackContext();
  const [showPopUp, setShowPopUp] = React.useState(false);
  const ref = React.useRef<HTMLDivElement | null>(null);

  const activeDevice = React.useMemo(
    () => playbackState?.device,
    [playbackState]
  );
  const availableDevices = React.useMemo(
    () => devices.filter((device) => device.id !== activeDevice?.id),
    [activeDevice, devices]
  );

  React.useEffect(() => {
    const clearPopup = (e: Event) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(e.target)) {
        e.preventDefault();
        setShowPopUp(false);
      }
    };
    document.addEventListener("click", clearPopup, true);
    return () => {
      document.removeEventListener("click", clearPopup, true);
    };
  }, []);
  return (
    <Wrapper>
      <DeviceDisplay>
        {activeDevice ? (
          <Current>
            <div>Listening on </div>
            <ActiveDevice onClick={() => setShowPopUp((prev) => !prev)}>
              {activeDevice.name}
            </ActiveDevice>
          </Current>
        ) : (
          <>
            <ActiveDevice onClick={() => setShowPopUp((prev) => !prev)}>
              No active device
            </ActiveDevice>
          </>
        )}
        <IconButton
          onClick={() => {
            window.location.reload();
          }}
        >
          ⟳
        </IconButton>
      </DeviceDisplay>
      <PopUp ref={ref}>
        <PopUpContent show={showPopUp}>
          <TitleText>Select another device</TitleText>
          {!availableDevices?.length && (
            <DeviceOptionButton disabled>
              No available devices
            </DeviceOptionButton>
          )}
          {availableDevices.map((device) => (
            <DeviceOptionButton
              key={device.id}
              onClick={async () => {
                await transferPlayback(device.id);
                setShowPopUp(false);
              }}
            >
              {device.name}
            </DeviceOptionButton>
          ))}
        </PopUpContent>
      </PopUp>
    </Wrapper>
  );
};

export default DevicesList;
