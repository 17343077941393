import React from "react";
import axios, { AxiosError } from "axios";
import { SpotifyTrack, type SpotifyAlbum } from "../type";
import { LyricsLine, useTranslationContext } from "./translation";

type PlaybackProps = {
  token?: string;
  album?: SpotifyAlbum;
  fetchAlbum: (k: string) => void;
  searchAlbum: (k: string) => Promise<AlbumSearchResult | undefined>;
  getCurrentlyPlaying: () => Promise<PlaybackState | undefined>;
  play: (v: string) => Promise<void>;
  pause: () => Promise<void>;
  devices: Device[];
  transferPlayback: (v: string) => Promise<void>;
  playbackState: PlaybackState | undefined;
  seekToPosition: (v: { startTime: number }) => Promise<void>;
  fetchLyrics: ({
    title,
    artist,
    lyricsId,
  }: {
    title: string;
    artist: string;
    lyricsId?: string;
  }) => Promise<LyricsLine[] | null | undefined>;
};

export type Device = {
  id: string;
  is_active: boolean;
  name: string;
};
export type AlbumSearchResult = {
  tracks: {
    items: any[];
  };
};

export type PlaybackState = {
  item: SpotifyTrack;
  is_playing: boolean;
  progress_ms: number;
  timestamp: number;
  device: Device;
};

const PlaybackContext = React.createContext<PlaybackProps>({} as PlaybackProps);

export const usePlaybackContext = () => {
  const values = React.useContext(PlaybackContext);
  return values;
};

type Props = {
  token?: string;
  updateToken: () => void;
  children: React.ReactNode;
};

export const PlaybackContextProvider: React.FC<Props> = ({
  token,
  updateToken,
  children,
}) => {
  const { findLyrics, getSynced } = useTranslationContext();
  const [album, setAlbum] = React.useState<SpotifyAlbum | undefined>(undefined);
  const [devices, setDevices] = React.useState<Device[]>([]);
  const [deviceId, setDeviceId] = React.useState<string | undefined>(undefined);

  const [playbackState, setPlaybackState] = React.useState<
    PlaybackState | undefined
  >();

  const authHeaders = React.useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  const handleError = React.useCallback(
    (e: AxiosError) => {
      if (e?.response?.status === 401) {
        updateToken();
      }
    },
    [updateToken]
  );
  const fetchAlbum = React.useCallback(
    async (albumId: string) => {
      if (album && album.id === albumId) return;
      if (!albumId) {
        setAlbum(undefined);
        return;
      }
      try {
        const url = `https://api.spotify.com/v1/albums/${albumId}`;
        const response = await fetch(url, {
          method: "GET",
          ...authHeaders,
        }).then((data) => data.json());
        setAlbum(response);
      } catch (e) {
        // Todo: handle Error
        console.log("cant fetch", e);
      }
    },
    [album, authHeaders]
  );

  const searchAlbum = React.useCallback(
    async (searchTerms: string) => {
      try {
        const response = await axios(
          `https://api.spotify.com/v1/search?q=${searchTerms}&type=track&limit=2`,
          {
            method: "GET",
            ...authHeaders,
          }
        );

        return response?.data as AlbumSearchResult;
      } catch (e) {
        handleError(e as AxiosError);
      }
    },
    [authHeaders, handleError]
  );

  const transferPlayback = React.useCallback(
    async (deviceId: string) => {
      try {
        await axios.put(
          "https://api.spotify.com/v1/me/player",
          {
            device_ids: [deviceId],
          },
          authHeaders
        );
      } catch (e) {
        handleError(e as AxiosError);
      }
    },
    [authHeaders, handleError]
  );

  const getCurrentlyPlaying = React.useCallback(async () => {
    try {
      const response = await axios(`https://api.spotify.com/v1/me/player`, {
        ...authHeaders,
      });
      const currentState = response?.data;
      setPlaybackState(currentState);
      if (currentState) {
        if (
          playbackState?.item?.id !== currentState.item?.id &&
          currentState.item
        ) {
          fetchAlbum(currentState.item.album.id);
        }
      }
      return currentState as PlaybackState;
    } catch (e) {
      // handle error
      handleError(e as AxiosError);
    }
  }, [authHeaders, fetchAlbum, handleError, playbackState]);

  const pause = React.useCallback(async () => {
    await getCurrentlyPlaying();
    await axios.put(
      "https://api.spotify.com/v1/me/player/pause",
      {},
      authHeaders
    );
  }, [authHeaders, getCurrentlyPlaying]);

  const listDevices = React.useCallback(async () => {
    try {
      const result = await axios.get(
        "https://api.spotify.com/v1/me/player/devices",
        authHeaders
      );
      const devices = result.data?.devices as Device[];
      const activeDevice = devices.filter((d) => d.is_active)[0];
      setDevices(devices);
      if (activeDevice) {
        setDeviceId(activeDevice.id);
        getCurrentlyPlaying();
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
  }, [authHeaders, getCurrentlyPlaying, handleError]);

  const play = React.useCallback(
    async (songUri: string) => {
      let offset = 0;
      if (!playbackState?.is_playing && playbackState?.item?.uri === songUri) {
        // Get offset;
        offset = playbackState?.progress_ms;
      }
      if (!deviceId) {
      }
      const url = `https://api.spotify.com/v1/me/player/play?device_id=${
        deviceId || playbackState?.device.id
      }`;
      try {
        await axios.put(
          url,
          {
            uris: [songUri],
            position_ms: offset,
          },
          authHeaders
        );
        // set repeat mode
        await getCurrentlyPlaying();
      } catch (e) {
        handleError(e as AxiosError);
      }
    },
    [playbackState, deviceId, authHeaders, getCurrentlyPlaying, handleError]
  );

  const seekToPosition = React.useCallback(
    async ({ startTime }: { startTime: number }) => {
      if (!playbackState?.is_playing && playbackState?.item) {
        await play(playbackState?.item?.uri);
      }
      const url = `https://api.spotify.com/v1/me/player/seek?position_ms=${startTime.toFixed(
        0
      )}`;
      await axios.put(url, {}, authHeaders);
    },
    [playbackState, authHeaders, play]
  );

  const fetchLyrics = React.useCallback(
    async ({
      title,
      artist,
      lyricsId,
    }: {
      title: string;
      artist: string;
      lyricsId?: string;
    }) => {
      const result = await findLyrics({ title, artist, lyricsId });
      const syned = await getSynced(result);
      return syned;
    },
    [findLyrics, getSynced]
  );

  React.useEffect(() => {
    if (!devices?.length && token) {
      listDevices();
    }
  }, [listDevices, devices, token]);

  const value = React.useMemo(
    () => ({
      token,
      album,
      playbackState,
      fetchAlbum,
      searchAlbum,
      getCurrentlyPlaying,
      devices,
      play,
      pause,
      seekToPosition,
      fetchLyrics,
      transferPlayback,
    }),
    [
      token,
      album,
      playbackState,
      fetchAlbum,
      searchAlbum,
      getCurrentlyPlaying,
      pause,
      devices,
      play,
      seekToPosition,
      fetchLyrics,
      transferPlayback,
    ]
  );
  return (
    <PlaybackContext.Provider value={value}>
      {children}
    </PlaybackContext.Provider>
  );
};
