import React from "react";
import styled from "styled-components";
import { basePadding } from "../theme";
import GeneralButton from "./common/GeneralButton";
import { authUrl } from "../config";
import { TranslationContextProvider } from "../contexts/translation";
import { PlaybackContextProvider } from "../contexts/playback";
import AlbumViewer from "./AlbumViewer";

const Wrapper = styled.div`
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const ConnectWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  flex: 1;
  justify-content: center;
  text-align: center;
  h1 {
    margin-bottom: ${basePadding * 2}px;
  }
  h5 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: ${basePadding * 2}px;
  }
  h6 {
    font-size: 12px;
    font-weight: 400;
  }
`;

const getHash = () =>
  window.location.hash
    .substring(1)
    .split("&")
    .reduce<{ [key: string]: string }>((initial, item) => {
      if (item) {
        const parts = item.split("=");
        initial[parts[0]] = decodeURIComponent(parts[1]);
      }
      return initial;
    }, {});

const Main = () => {
  const goToAuth = React.useCallback(() => {
    window.location.href = authUrl;
  }, []);

  const [token, setToken] = React.useState<string>(getHash()?.access_token);
  const hashChangeListener = () => {
    const hash = getHash();
    if (hash.access_token) {
      setToken(hash.access_token);
    }
  };

  React.useEffect(() => {
    window.addEventListener("hashchange", hashChangeListener);
    return () => {
      window.removeEventListener("hashchange", hashChangeListener);
    };
  }, []);

  return (
    <Wrapper>
      {!token && (
        <ConnectWrapper>
          <div>
            <h1>Language Learning with Music</h1>
            <h5>
              A Lyrics Prompter with Segmented Translations and Transliterations
            </h5>
            <GeneralButton onClick={goToAuth}>Connect Spotify</GeneralButton>
            <h6>For whitelisted users only at the moment.</h6>
          </div>
        </ConnectWrapper>
      )}

      <TranslationContextProvider>
        <PlaybackContextProvider token={token} updateToken={goToAuth}>
          <AlbumViewer />
        </PlaybackContextProvider>
      </TranslationContextProvider>
    </Wrapper>
  );
};
export default Main;
