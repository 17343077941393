import React from "react";
import styled from "styled-components";
import {
  ActiveTranslation,
  TranslatedText,
  useTranslationContext,
} from "../../contexts/translation";

const Wrapper = styled.div<{ show: boolean }>`
  ${({ show }) =>
    show
      ? `
        max-height: 200px;
        padding: 16px;
        overflow: auto;
        text-align:left;
        background: rgba(255, 255, 255, 0.95);
        margin: 8px;
        border-radius: 8px;
        `
      : `height: 0`};
`;
const Title = styled.div`
  display: flex;
  & > :first-child {
    flex: 1;
  }
  align-items: end;
`;
const ContentHeader = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const OriginalLine = styled.div`
  text-align: right;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    color: #999;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    padding-left: 16px;
  }
`;
const OriginalLineTranslated = styled.div`
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
const Translit = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
`;
const ContentTitle = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  font-size: 18px;
  margin: 4px 0px;
  > div:first-of-type {
    font-size: 14px;
  }
`;
type InfoPanelProps = {
  jumpToLine: (line: any) => void;
};
const InfoPanel = ({ jumpToLine }: InfoPanelProps) => {
  const tooltipRef = React.useRef(null);
  const { activeTranslation, setActiveTranslation } = useTranslationContext();

  React.useEffect(() => {
    const clearTooltip = (e: Event) => {
      // @ts-ignore
      if (tooltipRef.current && !tooltipRef.current.contains(e.target)) {
        setActiveTranslation(undefined);
      }
    };
    document.addEventListener("click", clearTooltip, true);
    return () => {
      document.removeEventListener("click", clearTooltip, true);
    };
  }, [setActiveTranslation]);

  const { dict, sentences, translit } = React.useMemo(
    () => (activeTranslation?.result || {}) as TranslatedText,
    [activeTranslation]
  );

  return (
    <Wrapper ref={tooltipRef} show={!!activeTranslation}>
      <Title>
        <ContentHeader>
          {sentences?.[0]?.orig} {translit && <Translit>({translit})</Translit>}
          <br />
          {sentences?.[0]?.trans}
        </ContentHeader>
        <OriginalLine onClick={() => jumpToLine(activeTranslation?.line)}>
          {activeTranslation?.line.text}
          <OriginalLineTranslated>
            {activeTranslation?.line.translatedText?.sentences
              ?.map((s) => s.trans)
              .join("")}
          </OriginalLineTranslated>
        </OriginalLine>
      </Title>

      {!!dict?.length && (
        <>
          <hr />
          {dict?.map(({ entry, pos }, i) => (
            <ContentTitle key={i}>
              <div>{pos}</div>
              {entry.map((e, eIndx) => e.word).join(", ")}
            </ContentTitle>
          ))}
        </>
      )}
    </Wrapper>
  );
};

export default React.memo(InfoPanel);
