import React from "react";
import styled from "styled-components";
import { SpotifyTrack } from "../../type";

export const Button = styled.button<{ isPlaying?: boolean }>`
  border: 0;
  width: 100%;
  background: #222;
  padding: 12px 18px;
  font-size: 20px;
  text-align: left;
  flex: 1;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ isPlaying }) =>
    isPlaying &&
    `
    color: #1db954;
    font-weight: 600;
`}
  &:hover {
    transform: scale(1.05);
    border-radius: 8px;
    color: #1db954;
  }
`;

const PlayIcon = styled.span`
  margin-right: 8px;
`;

type TrackButtonProps = {
  isPlaying: boolean;
  onClick: () => void;
  track: SpotifyTrack;
};
const TrackButton = ({ isPlaying, onClick, track }: TrackButtonProps) => {
  return (
    <Button disabled={isPlaying} isPlaying={isPlaying} onClick={onClick}>
      {isPlaying && <PlayIcon>♫</PlayIcon>}
      {track.name}
    </Button>
  );
};

export default TrackButton;
