import React from "react";
import styled from "styled-components";
import { PlaybackState, usePlaybackContext } from "../../contexts/playback";
import { LyricsLine } from "../../contexts/translation";
import { SpotifyTrack } from "../../type";

const Wrapper = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  color: #fff;
`;
const TimerBase = styled.div`
  background: rgba(200, 200, 200, 0.2);
  border-radius: 4px;
  margin: 0 8px;
  height: 5px;
  position: relative;
  flex: 1;
`;
const Progress = styled.div<{ percentage: number }>`
  background: green;
  height: 5px;
  width: ${({ percentage }) => `${percentage * 100}%`};
  position: absolute;
  border-radius: inherit;
  transition: 0.1s ease-in;
`;

const buffer = 200;

const formatMs = (ms: number) => {
  const min = Math.floor(ms / (1000 * 60));
  const sec = Math.floor((ms - min * 60000) / 1000);
  return `${min}:${`${sec}`.padStart(2, "0")}`;
};

const findCurrentSlot = (slots: LyricsLine[] = [], position = 0) => {
  let result = -1;
  let i = 0;
  do {
    const min = slots[i]?.startTime;
    const max = slots[i + 1]?.startTime;
    if (position < min && position < max) {
      result = 0;
    } else if (position >= min && position < max) {
      result = i;
    }
    i += 1;
  } while (i < slots.length && result <= -1);
  return result;
};
type TrackProgressProps = {
  lyrics?: LyricsLine[] | null;
  activeSlot: number;
  updateActiveSlot: (v: number) => void;
};
const TrackProgress = ({
  lyrics,
  activeSlot,
  updateActiveSlot,
}: TrackProgressProps) => {
  const { getCurrentlyPlaying } = usePlaybackContext();
  const [currentState, setCurrentState] = React.useState<
    PlaybackState | undefined
  >();

  const updateTime = React.useCallback(async () => {
    const newState = await getCurrentlyPlaying();
    if (newState && !document.hidden) {
      setCurrentState(newState);
      if (!newState.is_playing) return;
      if (lyrics) {
        const currentSlot = findCurrentSlot(
          lyrics,
          newState.progress_ms + buffer
        );
        if (currentSlot !== activeSlot) {
          updateActiveSlot?.(currentSlot);
        }
        if (currentSlot <= -1) {
          // Last slot
          updateActiveSlot?.(lyrics.length - 1);
        }
      }
    }
  }, [activeSlot, getCurrentlyPlaying, lyrics, updateActiveSlot]);

  React.useEffect(() => {
    const id = setInterval(updateTime, 1000);
    return () => clearInterval(id);
  }, [updateTime]);

  const { lapsed, currentTrack } = React.useMemo(() => {
    return {
      lapsed: currentState?.progress_ms || 0,
      currentTrack: currentState?.item,
    } as {
      lapsed: number;
      position: number;
      currentTrack?: SpotifyTrack;
    };
  }, [currentState]);

  return (
    <Wrapper>
      <div>{formatMs(lapsed)}</div>
      <TimerBase>
        <Progress
          percentage={currentTrack ? lapsed / currentTrack.duration_ms : 0}
        />
      </TimerBase>
      <div>{currentTrack && formatMs(currentTrack.duration_ms)}</div>
    </Wrapper>
  );
};
export default React.memo(TrackProgress);
