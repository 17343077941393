import React, { Dispatch, SetStateAction } from "react";
import axios from "axios";
import { v4 } from "uuid";

type Token = {
  id: string;
  text: string;
};

export type ActiveTranslation = {
  result: TranslatedText;
  token: Token;
  line: LyricsLine;
};

type LyricsSearchResult = {
  id: string;
  name: string;
}[];
type LineListProps = { lrc?: { lyric?: string } };

type TranslatedTextSentence = {
  trans?: string;
  orig?: string;
  src_translit?: string;
};

type Dict = {
  base_form: string;
  entry: {
    word: string;
  }[];
  pos: string;
  terms: string[];
};
export type TranslatedText = {
  confidence: number;
  ld_result: any;
  sentences: TranslatedTextSentence[];
  src: string;
  translit?: string;
  dict?: Dict[];
};
export type LyricsLine = {
  id: string;
  startTime: number;
  text: string;
  translatedText?: TranslatedText;
  tokenized?: any[];
};

type TranslationProps = {
  language: string;
  updateLanguage: (k: string) => void;
  setActiveTranslation: Dispatch<SetStateAction<ActiveTranslation | undefined>>;
  activeTranslation?: ActiveTranslation;
  tokenHighlight: boolean;
  getSynced: (
    v: LineListProps | null
  ) => Promise<LyricsLine[] | undefined | null>;
  findLyrics: (v: {
    title: string;
    artist: string;
    lyricsId?: string;
    searchCallback?: (r: any) => void;
  }) => Promise<LineListProps | null>;
  setTokenHighlight: (v: boolean) => void;
  getTranslation: (
    v: string,
    config?: { detailed?: boolean; from?: string }
  ) => Promise<TranslatedText | undefined>;
};

const TranslationContext = React.createContext<TranslationProps>(
  {} as TranslationProps
);

export const useTranslationContext = () => {
  const values = React.useContext(TranslationContext);
  return values;
};

export const TranslationContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [language, setLanguage] = React.useState(
    localStorage.getItem("translation_language") || "en"
  );
  const [tokenHighlight, setTokenHighlight] = React.useState(false);
  const [activeTranslation, setActiveTranslation] = React.useState<
    ActiveTranslation | undefined
  >();

  const updateLanguage = React.useCallback((newLang: string) => {
    setLanguage(newLang);
    localStorage.setItem("translation_language", newLang);
  }, []);

  const getTranslation = React.useCallback(
    async (text: string, config?: { detailed?: boolean; from?: string }) => {
      const params = [
        "client=gtx",
        `sl=${config?.from || "auto"}`,
        `tl=${language}`,
        "hl=en-UK",
        "dt=t",
        "dt=bd",
        "dt=rm",
        ...[config?.detailed ? ["dt=ld"] : []],
        "dj=1",
        "source=input",
        "tk=29979.29979",
        `q=${text}`,
      ].join("&");
      const url = `https://translate.googleapis.com/translate_a/single?${params}`;
      try {
        const result = await axios.get(url);
        const translatedData = result?.data;
        const translit = translatedData?.sentences?.filter(
          (s: TranslatedTextSentence) => !!s.src_translit
        )?.[0]?.src_translit;
        return { ...translatedData, translit };
      } catch (e) {}
    },
    [language]
  );

  const tokenizeText = React.useCallback((text: string, locale: string) => {
    const words = [];
    const segmenter = new Intl.Segmenter(locale, { granularity: "word" });
    const segments = segmenter.segment(text);

    for (let { segment } of segments) {
      words.push({ text: segment, id: v4() });
    }

    return words;
  }, []);

  const findLyrics = React.useCallback(
    async ({
      title,
      artist,
      lyricsId,
      searchCallback,
    }: {
      title: string;
      artist: string;
      lyricsId?: string;
      searchCallback?: (result: any) => void;
    }) => {
      const searchURL = `https://music.xianqiao.wang/neteaseapiv2/search?limit=10&type=1&keywords=`;
      const lyricURL = `https://music.xianqiao.wang/neteaseapiv2/lyric?id=`;

      const finalURL =
        searchURL + encodeURIComponent(`${title.slice(0, 37)} ${artist}`);
      try {
        let targetLyricsId = lyricsId;

        if (!targetLyricsId) {
          const searchResults = await axios.get(finalURL);
          const items: LyricsSearchResult = searchResults.data.result.songs;
          if (!!searchCallback) {
            searchCallback(items);
            return;
          }
          if (!items?.length) {
          }

          let itemId = -1;

          const firstMatch = items.findIndex(({ name }) => name === title);
          itemId = firstMatch > -1 ? firstMatch : itemId;
          if (itemId === -1) {
            const secondMatch = items.findIndex(
              (val) => val.name.toUpperCase() === title
            );
            itemId = secondMatch > -1 ? secondMatch : 0;
          }
          targetLyricsId = items[itemId].id;
        }

        const lyricsResult = await axios.get(lyricURL + targetLyricsId);
        return lyricsResult?.data;
      } catch (e) {
        // Todo: handle error
        console.log(e);
      }
    },
    []
  );

  const parseTimestamp = React.useCallback((line: string) => {
    const matchResult = line.match(/(\[.*?\])|([^\[\]]+)/g);
    if (!matchResult?.length || matchResult.length === 1) {
      return { text: line };
    }

    const textIndex = matchResult.findIndex((slice) => !slice.endsWith("]"));
    let text = "";

    if (textIndex > -1) {
      text = matchResult.splice(textIndex, 1)[0];
    }

    const time = matchResult[0].replace("[", "").replace("]", "");

    return { time, text };
  }, []);

  const containCredits = React.useCallback((text: string) => {
    const creditInfo = [
      "\\s?作?\\s*词|\\s?作?\\s*曲|\\s?编\\s*曲?|\\s?监\\s*制?",
      ".*编写|.*和音|.*和声|.*合声|.*提琴|.*录|.*工程|.*工作室|.*设计|.*剪辑|.*制作|.*发行|.*出品|.*后期|.*混音|.*缩混",
      "原唱|翻唱|题字|文案|海报|古筝|二胡|钢琴|吉他|贝斯|笛子|鼓|弦乐",
      "lrc|publish|vocal|guitar|program|produce|write",
    ];
    const creditInfoRegExp = new RegExp(
      `^(${creditInfo.join("|")}).*(:|：)`,
      "i"
    );
    return creditInfoRegExp.test(text);
  }, []);

  const getSynced = React.useCallback(
    async (lineList: LineListProps | null) => {
      let isInstrumental = false;

      const lyricStr = lineList?.lrc?.lyric;

      if (!lyricStr) {
        return null;
      }

      const processLine: (v: string) => Promise<LyricsLine | null> = (
        line: string
      ) => {
        return new Promise(async (resolve) => {
          const { time, text } = parseTimestamp(line);
          let showText = true;

          if (text.includes("纯音乐")) {
            isInstrumental = true;
            showText = false;
          }
          if (!time || !text) return resolve(null);
          const [key, value] = time.split(":") || [];
          const [min, sec] = [parseFloat(key), parseFloat(value)];
          if (!isNaN(min) && !isNaN(sec) && !containCredits(text)) {
            const translatedText =
              showText && text ? await getTranslation(text) : "";
            resolve({
              id: v4(),
              startTime: (min * 60 + sec) * 1000,
              text: showText ? text || "" : "",
              translatedText,
              tokenized: tokenizeText(text, translatedText.src),
            });
            return;
          }
          resolve(null);
        });
      };

      const lines: string[] = lyricStr
        .split(/\r?\n/)
        .map((line: string) => line.trim());

      try {
        const lyrics = (
          await Promise.all(lines.map((line) => processLine(line)))
        ).filter((a) => !!a) as LyricsLine[];
        if (!lyrics.length) {
          return null;
        }
        if (isInstrumental) {
          return [
            {
              id: "0",
              startTime: 0,
              text: "",
            },
          ];
        }
        return lyrics;
      } catch (e) {
        // Todo: handle error
        console.log(e);
      }
    },
    [containCredits, getTranslation, parseTimestamp, tokenizeText]
  );

  const value = React.useMemo(
    () => ({
      setActiveTranslation,
      activeTranslation,
      language,
      updateLanguage,
      getTranslation,
      tokenHighlight,
      setTokenHighlight,
      getSynced,
      findLyrics,
    }),
    [
      activeTranslation,
      language,
      updateLanguage,
      getTranslation,
      tokenHighlight,
      getSynced,
      findLyrics,
    ]
  );

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
};
