import React from "react";
import styled from "styled-components";
import {
  type LyricsLine as LyricsLineType,
  useTranslationContext,
} from "../../contexts/translation";
import { palette } from "../../theme";
import PlayIcon from "../common/PlayIcon";
import WordToken from "./WordToken";

const Wrapper = styled.div<{ isPlaying?: boolean; isActive?: boolean }>`
  transition: 0.1s ease-in-out;
  padding: 2px 16px;
  margin: 8px 0;
  border-radius: 8px;
  width: auto;
  position: relative;
  &:hover {
    color: #1db954;
  }
  ${({ isPlaying }) =>
    isPlaying &&
    `
  color:#1db954;
  @media (min-width: 768px) {
    font-size: 30px;
   }
  `}
`;
const PlayButton = styled.button<{ isPlaying: boolean }>`
  position: absolute;
  left: -16px;
  top: ${({ isPlaying }) => (isPlaying ? "16px" : "10px")};
  background: transparent;
  border-radius: 50%;
  padding: 0 0 0 6px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  height: 24px;
  width: 24px;
  font-size: 10px;
  text-align: center;
  &:hover {
    border-color: ${palette.spotifyGreen};
    color: ${palette.spotifyGreen};
  }
  @media (max-width: 768px) {
    left: -12px;
    ${({ isPlaying }) =>
      isPlaying &&
      `
      top: 12px;
    `}
  }
`;
const TranslatedText = styled.div`
  font-size: 14px;
  margin-top: 2px;
`;
const Translit = styled.div`
  ${({ isPlaying }) =>
    `
  font-size: ${isPlaying ? "20px" : "13px"};
  opacity: ${isPlaying ? 0.8 : 0.6};
    `}
`;
type LyricsLineProps = {
  line: LyricsLineType;
  isPlaying: boolean;
  jumpToLine: () => void;
};
const LyricsLine = ({ line, jumpToLine, isPlaying }: LyricsLineProps) => {
  const { activeTranslation } = useTranslationContext();
  const [hover, setHover] = React.useState(false);

  const [translated, translit] = React.useMemo(() => {
    return [
      line.translatedText?.sentences?.map((s) => s.trans).join(" "),
      line.translatedText?.translit,
    ];
  }, [line]);

  return (
    <Wrapper
      isActive={activeTranslation?.line?.id === line.id}
      isPlaying={isPlaying}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover && (
        <PlayButton onClick={jumpToLine} isPlaying={isPlaying}>
          <PlayIcon />
        </PlayButton>
      )}
      {line.tokenized?.map((token, idx) =>
        !token?.text?.trim() ? (
          <span key={idx} />
        ) : (
          <WordToken key={idx} line={line} token={token} onClick={() => {}} />
        )
      )}
      {translit && <Translit isPlaying={isPlaying}>({translit})</Translit>}
      <TranslatedText>{translated}</TranslatedText>
    </Wrapper>
  );
};
export default LyricsLine;
