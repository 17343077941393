import React from "react";
import styled from "styled-components";
import LanguageOption from "./LanguageOption";
import { languages } from "../../constants";
import { useTranslationContext } from "../../contexts/translation";

const PopUpContent = styled.div<{ show: boolean }>`
  position: absolute;
  top: 60px;
  right: 24px;
  z-index: 10;
  transition: 0.1s opacity ease-in;
  width: 200px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  user-select: none;
  display: flex;
  flex-direction: column;
  ${({ show }) =>
    show &&
    ` 
    padding: 8px 16px 16px 16px;
  height: 400px;
  overflow: auto;
  opacity: 1;
  background: rgb(22, 36, 46);
  border-radius: 8px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  button {
    display: block;
  }
`}
`;
const Content = styled.div`
  overflow: auto;
  margin-top: 8px;
`;

const TitleText = styled.div`
  padding: 8px 16px 4px 16px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
`;
const LanguageOptions = () => {
  const { language, updateLanguage } = useTranslationContext();
  const [showPopUp, setShowPopUp] = React.useState(false);
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const clearPopup = (e: MouseEvent) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(e.target)) {
        e.preventDefault();
        setShowPopUp(false);
      }
    };
    document.addEventListener("click", clearPopup, true);
    return () => {
      document.removeEventListener("click", clearPopup, true);
    };
  }, []);

  return (
    <div ref={ref}>
      <LanguageOption onClick={(e) => setShowPopUp((prev) => !prev)} selected>
        {language}
      </LanguageOption>
      <PopUpContent show={showPopUp}>
        <TitleText>Translate to</TitleText>
        <Content>
          {Object.keys(languages).map((op) => (
            <LanguageOption
              key={op}
              disabled={op === language}
              onClick={(e) => {
                e.preventDefault();
                updateLanguage(op);
                setShowPopUp(false);
              }}
              selected={op === language}
            >
              {languages[op]}
            </LanguageOption>
          ))}
        </Content>
      </PopUpContent>
    </div>
  );
};

export default LanguageOptions;
