import React, { FormEvent, MouseEvent } from "react";
import styled from "styled-components";
import {
  usePlaybackContext,
  type AlbumSearchResult,
} from "../../contexts/playback";
import GeneralButton from "../common/GeneralButton";
import DevicesList from "../Devices/DevicesList";
import LanguageOptions from "../Translation/LanguageOptions";

const Input = styled.input`
  padding-right: 30px;
  font-family: inherit;
  border: none;
  background: #fff;
  outline: none;
  transition: 0.1s ease-in;
  padding: 12px 30px;
  font-size: 18px;
  font-weight: 500;
  margin: 8px;
  border-radius: 30px;
  &:focus {
    box-shadow: inset 0 0 0 2px #1db954;
  }
`;
const Wrapper = styled.div`
  padding: 16px 24px 0 24px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 16px 16px 0 16px;
  }
`;
const ActionWrapper = styled.div`
  position: relative;
`;
const InputAction = styled.button`
  position: absolute;
  right: 15px;
  top: 20%;
  padding: 0;
  font-size: 24px;
  width: 40px;
  height: 40px;
  background: transparent;
`;

const ActionContent = styled.div<{ withContent: boolean }>`
  position: absolute;
  border-radius: 8px;
  z-index: 5;
  top: -4px;
  background: rgb(22, 36, 46);
  ${({ withContent }) =>
    !withContent &&
    `
  padding: 8px 16px 16px 16px;
  `}
`;
const TabButton = styled.button<{ active: boolean }>`
  font-size: 18px;
  font-weight: 600;
  font-family: inherit;
  color: #fff;
  background: transparent;
  display: inline-block;
  border-bottom: 2px solid transparent;
  margin-right: 8px;
  ${({ active }) =>
    active &&
    `
border-bottom: 2px solid #1db954 !important;
`}
  @media (max-width: 768px) {
    padding: 0;
  }
`;
const DesktopOnly = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const FlexBox = styled.div<{ flex?: number }>`
  display: flex;
  align-items: center;
  ${({ flex }) => flex !== null && `flex: ${flex};`}
`;

const HeaderFlex = styled(FlexBox)`
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;
const InputGroup = styled.div`
  position: relative;
  display: inline-block;
`;

const SearchResult = styled.button`
  border: 0;
  width: 100%;
  background: #222;
  padding: 12px 18px;
  font-size: 20px;
  text-align: left;
  color: #fff;
  &:hover {
    transform: scale(1.05);
    background: #333;
  }
`;
const SearchResultWrapper = styled.div`
  margin: 16px;
`;
const Header = () => {
  const { fetchAlbum, searchAlbum } = usePlaybackContext();

  const [showSelection, setShowSelection] = React.useState(true);
  const [activeTab, setActiveTab] = React.useState<string | null>(null);

  const [searchValue, setSearchValue] = React.useState("");
  const [searchResult, setSearchResult] = React.useState<
    AlbumSearchResult | undefined
  >();
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const clearPopup = (e: Event) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(e.target)) {
        e.preventDefault();
        setActiveTab(null);
      }
    };
    document.addEventListener("click", clearPopup, true);
    return () => {
      document.removeEventListener("click", clearPopup, true);
    };
  }, []);

  const selectAlbum = React.useCallback(
    async (id: string) => {
      await fetchAlbum(id);
      setShowSelection(false);
      setActiveTab(null);
    },
    [fetchAlbum]
  );
  const handleSearch = React.useCallback(async () => {
    const data = await searchAlbum(searchValue);
    setSearchResult(data);
  }, [searchAlbum, searchValue]);
  const selectTab = React.useCallback((tab: string) => {
    setActiveTab((prev) => (prev === tab ? null : tab));
    setShowSelection(true);
  }, []);

  const clearSearch = React.useCallback(() => {
    setSearchResult(undefined);
    setSearchValue("");
  }, []);

  return (
    <Wrapper>
      <HeaderFlex>
        <FlexBox flex={1}>
          <DesktopOnly>
            <TabButton
              active={activeTab === "search"}
              onClick={() => selectTab("search")}
            >
              Search
            </TabButton>
          </DesktopOnly>
          <DevicesList />
        </FlexBox>

        <LanguageOptions />
      </HeaderFlex>
      <ActionWrapper>
        {showSelection && (
          <ActionContent withContent={!activeTab} ref={ref}>
            {activeTab === "search" && (
              <>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSearch();
                  }}
                >
                  <InputGroup>
                    <Input
                      type="text"
                      aria-label="search"
                      placeholder="Search..."
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    {!!searchValue && (
                      <InputAction type="button" onClick={clearSearch}>
                        ╳
                      </InputAction>
                    )}
                  </InputGroup>

                  <GeneralButton
                    type="submit"
                    onClick={handleSearch}
                    disabled={!searchValue}
                  >
                    Search
                  </GeneralButton>
                </form>
                {searchResult?.tracks && (
                  <SearchResultWrapper>
                    {searchResult.tracks.items.map((item) => (
                      <SearchResult
                        key={item.id}
                        onClick={() => selectAlbum(item.album.id)}
                      >
                        {item.artists[0].name} - {item.name}
                      </SearchResult>
                    ))}
                  </SearchResultWrapper>
                )}
              </>
            )}
          </ActionContent>
        )}
      </ActionWrapper>
    </Wrapper>
  );
};

export default Header;
