import React from "react";
import styled from "styled-components";
import TrackButton from "./TrackButton";
import { usePlaybackContext } from "../../contexts/playback";
import { SpotifyTrack } from "../../type";

const Content = styled.div`
  flex: 1;
  padding: 0 16px 16px 16px;
  margin: 0 8px;
`;
const TracksList = () => {
  const { album, play, playbackState } = usePlaybackContext();
  const tracks = album?.tracks;

  const handleSelectSong = React.useCallback(
    (item: SpotifyTrack) => {
      if (item.id !== playbackState?.item?.id) {
        play(item.uri);
      }
    },
    [play, playbackState?.item]
  );

  return (
    <Content>
      {tracks?.items?.map((track) => (
        <TrackButton
          key={track.id}
          isPlaying={track.id === playbackState?.item?.id}
          onClick={() => handleSelectSong(track)}
          track={track}
        />
      ))}
    </Content>
  );
};

export default TracksList;
