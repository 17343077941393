import React from "react";
import styled from "styled-components";
import {
  LyricsLine,
  TranslatedText,
  useTranslationContext,
} from "../../contexts/translation";

const Wrapper = styled.div<{ isActive: boolean }>`
  padding: 4px 2px;
  border-radius: 8px;
  cursor: pointer;
  display: inline;
  &:hover {
    color: #5ef794;
    background: rgba(255, 255, 255, 0.25);
  }
  ${({ isActive }) =>
    isActive &&
    `
    color: #1db954;
    background: rgba(255, 255, 255, 0.25);
    `};
`;
type WordTokenProps = {
  token: {
    id: string;
    text: string;
  };
  line: LyricsLine;
  onClick: () => void;
};
const WordToken = ({ token, line, onClick }: WordTokenProps) => {
  const {
    setActiveTranslation,
    activeTranslation,
    getTranslation,
    setTokenHighlight,
  } = useTranslationContext();
  const [translationResult, setTranslationResult] = React.useState<
    TranslatedText | undefined
  >();

  const updateAsActiveTranslation = React.useCallback(
    (result: TranslatedText) => {
      setActiveTranslation({ token, result, line });
    },
    [setActiveTranslation, token, line]
  );
  const translateText = React.useCallback(
    async (text: string) => {
      if (!!translationResult) {
        updateAsActiveTranslation(translationResult);
        return;
      }
      const translated = await getTranslation(text, {
        detailed: true,
        from: line?.translatedText?.src,
      });
      if (translated) {
        setTranslationResult(translated);
        updateAsActiveTranslation(translated);
      }
    },
    [getTranslation, line, translationResult, updateAsActiveTranslation]
  );
  return (
    <Wrapper
      onClick={(e) => {
        translateText(token.text);
        onClick?.();
      }}
      isActive={activeTranslation?.token?.id === token.id}
      onMouseEnter={() => setTokenHighlight(true)}
      onMouseLeave={() => setTokenHighlight(false)}
    >
      {token?.text}
    </Wrapper>
  );
};

export default WordToken;
