import React from "react";
import styled from "styled-components";
import AlbumInfoHeader from "./AlbumInfoHeader";
import TracksList from "./TracksList";

const Wrapper = styled.div<{ expanded?: boolean; relative?: boolean }>`
  transition: 0.2s ease-in;
  height: ${({ expanded }) => (expanded ? "100%" : "250px")};
  position: ${({ relative }) => (relative ? "relative" : "absolute")};
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`;
const Content = styled.div`
  background: rgba(30, 30, 30, 0.6);
  flex: 1;
  display: flex;
  overflow: auto;
  flex-direction: column;
`;
const ContentWrapper = styled.div`
  overflow: auto;
`;
const FloatContainer = styled.div`
  position: relative;
`;

const IconButton = styled.button<{ upwards: boolean }>`
  background: transparent;
  font-size: 24px;
  position: absolute;
  top: -50px;
  right: 32px;
  color: rgba(255, 255, 255, 1);
  ${({ upwards }) =>
    upwards
      ? `
      transform:  rotate(135deg);
  `
      : `
      transform:  rotate(-45deg);
      margin-top: 16px;

  `}
  &:hover {
    color: rgba(255, 255, 255, 0.75);
  }
`;
const InfoWidget = () => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Wrapper expanded={expanded}>
      <Content>
        <AlbumInfoHeader />
        <FloatContainer>
          <IconButton
            onClick={() => setExpanded((prev) => !prev)}
            upwards={expanded}
          >
            ◥
          </IconButton>
        </FloatContainer>
        <ContentWrapper>
          <TracksList />
        </ContentWrapper>
      </Content>
    </Wrapper>
  );
};

export default InfoWidget;
