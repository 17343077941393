import React from "react";
import { usePlaybackContext } from "../contexts/playback";
import Header from "./Album/Header";
import styled from "styled-components";
import InfoWidget from "./Album/InfoWidget";
import TrackContent from "./Track/TrackContent";
import { palette } from "../theme";

const AlbumWrapper = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: 50% 50%;
  overflow: auto;
  position: relative;
  padding: 16px 8px 16px 16px;
  @media (max-width: 768px) {
    grid-template-columns: 100%;
    padding: 0;
  }
`;
const AlbumArt = styled.div<{ image?: string }>`
  text-align: center;
  font-size: 24px;
  flex: 1;
  min-height: 400px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  ${({ image }) =>
    image
      ? `
    background-image: ${`url(${image})`};
    `
      : `background: linear-gradient(
        ${palette.spotifyGreen},
        ${palette.pink},
        ${palette.purple}
      );`}
  @media (max-width: 768px) {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    top: 0;
  }
`;

const HiddenOnDesktop = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const MobileBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.85);
  z-index: -1;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  @media (min-width: 768px) {
    display: none;
  }
`;
const AppDescription = styled.div`
  color: #fff;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const AlbumViewerContent = () => {
  const { album, playbackState } = usePlaybackContext();

  return (
    <AlbumWrapper>
      <AlbumArt image={album?.images?.[0]?.url}>
        {!!album && (
          <HiddenOnDesktop>
            <InfoWidget />
          </HiddenOnDesktop>
        )}
      </AlbumArt>
      {playbackState?.item ? (
        <TrackContent>
          <MobileBackdrop />
        </TrackContent>
      ) : (
        <AppDescription>
          <div>Start Listening on your own device and Refresh</div>
        </AppDescription>
      )}
    </AlbumWrapper>
  );
};
type WrapperProps = { shouldFlex: boolean };
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: ${({ shouldFlex }) => (shouldFlex ? 1 : 0)};
`;

const Footer = styled.div`
  color: #fff;
  font-size: 12px;
  padding: 0 16px 16px 16px;
  @media (max-width: 768px) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;
const Link = styled.a`
  color: #fff;
`;

const AlbumViewer = () => {
  const { token } = usePlaybackContext();
  return (
    <>
      <Wrapper shouldFlex={!!token}>
        {token && <Header />}
        <AlbumViewerContent />
      </Wrapper>
      <Footer>
        A tool to learn language while listening to your favourite songs on
        Spotify by{" "}
        <Link href="https://ctrlncode.com/" target="_blank">
          Ctrl n Code
        </Link>
      </Footer>
    </>
  );
};
export default AlbumViewer;
