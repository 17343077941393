import styled from "styled-components";
import { palette } from "../../theme";

const Button = styled.button`
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 500;
  margin: 8px;
  border-radius: 30px;
  cursor: pointer;
  background: linear-gradient(
    to right,
    ${palette.spotifyGreen} 0% 50%,
    #fff 50% 100%
  );
  background-size: 200%;
  transition: 0.25s ease-in;
  color: #000;
  background-position-x: right;
  &:hover {
    background-size: 200%;
    color: #fff;
    background-position-x: left;
  }
  &:disabled {
    background-size: 200%;
    color: #bbb;
    cursor: default;
    background-position-x: right;
  }
`;

export default Button;
