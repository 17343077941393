import React from "react";
import styled from "styled-components";
import { usePlaybackContext } from "../../contexts/playback";

const Header = styled.div`
  text-align: left;
  padding: 16px 16px 16px 24px;
  & * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h2 {
    font-size: 36px;
  }
  h4 {
    font-size: 24px;
  }
  h6 {
    margin-top: 4px;
    font-size: 14px;
    font-weight: normal;
  }
`;
const AlbumInfoHeader = () => {
  const { album } = usePlaybackContext();

  return (
    <Header>
      <div style={{ overflow: "hidden" }}>
        <h2>{album?.name}</h2>
        <h4>{album?.artists?.map((art) => art.name).join(", ")}</h4>
        <h6>{album?.release_date}</h6>
      </div>
    </Header>
  );
};

export default AlbumInfoHeader;
