import styled from "styled-components";
import { palette } from "../../theme";

const LanguageOption = styled.button<{ selected: boolean }>`
  padding: 4px 8px;
  min-width: 50px;
  border-radius: 20px;
  cursor: pointer;
  background: transparent;
  color: #fff;
  font-size: 18px;
  &:hover {
    color: ${palette.spotifyGreen};
  }
  ${({ selected }) =>
    selected &&
    `
    border: 1px solid #fff;
    &:hover {
      color: #fff;
    }
  `}
`;

export default LanguageOption;
